export enum Pages {
  HOME = 'HOME',
  HOW_IT_WORKS = 'HOW_IT_WORKS',
  WHY_US = 'WHY_US',
  SERVICES = 'SERVICES',
  FAQ = 'FAQ',
  CONTACT_US = 'CONTACT_US',
}
export enum FaqPanel {
  FIRST = 'FIRST_PANEL',
  SECOND = 'SECOND_PANEL',
  THIRD = 'THIRD_PANEL',
  FOURTH = 'FOURTH_PANEL',
  FIFTH = 'FIFTH_PANEL',
  SIXTH = 'SIXTH_PANEL',
  SEVENTH = 'SEVENTH_PANEL'
}